html, body {
  height: 100%;
}

@font-face {
  font-family: 'RAIDENGRAY';
  src: url('./fonts/GOINGTODOGREATTHINGS.TTF') format('truetype');
  /* Add more formats as needed, like TTF, EOT, etc. */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RAIDENGRAY2';
  src: url('./fonts/RAIDENGRAY2.TTF') format('truetype');
  /* Add more formats as needed, like TTF, EOT, etc. */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RAIDENGRAY3';
  src: url('./fonts/RAIDENGRAY3.TTF') format('truetype');
  /* Add more formats as needed, like TTF, EOT, etc. */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RAIDENGRAY4';
  src: url('./fonts/RAIDENGRAY4.TTF') format('truetype');
  /* Add more formats as needed, like TTF, EOT, etc. */
  font-weight: lighter;
  font-style: normal;
}

.bg {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #8da1b4;

  background-repeat: repeat;
  background-image: url('../images/bg.png');

  background-size: cover;
  background-position: center;
  margin-bottom: 80px;
}

.baby-registry {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  margin-bottom: 80px;
}

.baby-registry h1 {

  padding: 50px;
  margin-bottom: 20px;
  color:#ffffff;
  font-size: 80px;
  font-family: 'RAIDENGRAY2';
}
.baby-registry h2 {

  padding: 10px;
  margin-bottom: 5px;
  color:#ffffff;
  font-size: 40px;
  font-family: 'RAIDENGRAY2';
}
.baby-registry p {

  padding: 10px;
  margin-bottom: 20px;
  color:#ffffff;
  font-size: 40px;
  font-family: 'RAIDENGRAY';
}
.baby-registry p.span {

  padding: 5px;
  margin-bottom: 20px;
  color:#ffffff;
  font-size: 40px;
  font-family: 'RAIDENGRAY2';
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  .baby-registry {
    
    background-position: top;
  }

  .baby-registry h1 {
    padding: 0px;
    padding-bottom: 70px;
    font-size: 45px;
  }
}

.registry-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 75%;
  margin-bottom: 60px;
}

.registry-items-got {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 75%;
  margin-bottom: 60px;
}

.card {
  width: 300px;
  background-color: #8cdde783;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.404);
  padding: 20px;
  align-content: center;
}

.card2 {
  width: 300px;
  background-color: #8ce7a783;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.404);
  padding: 20px;
}
.item-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.item-description {
  margin-top: 0px;
}

h1 {

  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 10px;
}

a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #3697ff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
}

a:hover {
  background-color: #0056b3;
}

.fund {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

a {
  display: inline-block;
  padding: 20px 20px;
  background-color: #526274;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 30px;
  font-family: 'RAIDENGRAY3';
  
}

a:hover {
  background-color: #242424;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 400px;
  }
}
